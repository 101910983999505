<template>
    <div class="stage-summary">
        <div class="summary-error" v-if="order_error_msg">
            <b>ERROR:</b> {{ order_error_msg }}
        </div>
        <app-table-details
            :rows="rows"
            :data="data"
        ></app-table-details>

        <button class="btn btn-primary btn-small" @click="confirm">Order</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTableDetails from '@/components/app-table-details'
import formatDate from '@/helpers/format-date'

export default {
    props: {
        broadband: { type: Object, required: true },
        order_error_msg: { type: String },
    },

    components: {
        appTableDetails,
    },

    methods: {
        init() {},

        confirm() {
            this.$emit('confirm')
        },
    },

    computed: {
        ...mapGetters([
            'getFibreOrderTypesOrdering',
        ]),
        customer() {
            const fullname = [this.broadband.customer.FirstName, this.broadband.customer.LastName].join(' ').trim()

            return fullname || this.broadband.customer.Email || '[External Ref 1: '+this.broadband.customer.ExternalRef1+ ']' || '?'
        },

        planTermSuffix() {
            return this.broadband.plan && this.broadband.plan.product && this.broadband.plan.product.TermLength
                ? ` [${this.broadband.plan.product.TermLength} month term]`
                : ''
        },

        plan_name() {
            return this.broadband.plan && this.broadband.plan.product && this.broadband.plan.product.Name
                ? this.broadband.plan.product.Name
                : '?'
        },

        plan_description() {
            return this.broadband.plan && this.broadband.plan.product && this.broadband.plan.product.Description
                ? this.broadband.plan.product.Description + this.planTermSuffix
                : '?'
        },

        plan_rfs_date() {
            return this.broadband.plan && this.broadband.plan.rfs_date
                ? formatDate(this.broadband.plan.rfs_date, "YYYY-MM-DD")
                : ''
        },

        plan_price() {
            return this.broadband.plan && this.broadband.plan.product && this.broadband.plan.product.Cents || this.broadband.plan.product.Cents == 0
                ? `$${ this.broadband.plan.product.Cents % 100 == 0 ? this.broadband.plan.product.Cents / 100 : (this.broadband.plan.product.Cents / 100).toFixed(2) }`
                : '?'
        },

        order_type() {
            if (!this.broadband.plan || !this.broadband.plan.order_type) {
                return ''
            }
            if (this.broadband.plan.order_type in this.getFibreOrderTypesOrdering) {
                return this.getFibreOrderTypesOrdering[this.broadband.plan.order_type].title
            }
            for (const ot in this.getFibreOrderTypesOrdering) {
                if (this.getFibreOrderTypesOrdering[ot].value == this.broadband.plan.order_type) {
                    return this.getFibreOrderTypesOrdering[ot].title
                }
            }
            return '?'
        },

        service_type() {
            if (this.broadband.plan.is_service_managed) {
                return 'Managed Service'
            } else {
                return 'Standard / Unmanaged'
            }
        },

        lan_ip_range() {
            return this.broadband.plan.LAN_IP_Range ? this.broadband.plan.LAN_IP_Range : ''
        },

        wan_ip() {
            return this.broadband.plan.WAN_IP ? this.broadband.plan.WAN_IP : ''
        },

        vrf() {
            return (!this.broadband.plan.is_service_managed && this.broadband.plan.VRF_Details) ? this.broadband.plan.VRF_Details : ''
        },

        additional_details() {
            return (!this.broadband.plan.is_service_managed && this.broadband.plan.Additional_Details) ? this.broadband.plan.Additional_Details : ''
        },

        dhcp_scope() {
            if (this.broadband.plan.DHCP_Scope) {
                if (this.broadband.plan.IP_Helper_Details) {
                    return `Yes - Helper IP: ${this.broadband.plan.IP_Helper_Details}`
                } else {
                    return 'Yes (No helper IP)'
                }
            } else {
                return ''
            }
        },

        site_contact() {
            if (!this.broadband.contact_details.SiteContactName) {
                return ''
            }

            return `${this.broadband.contact_details.SiteContactName}, ${this.broadband.contact_details.SiteContactPhoneNumber}`
        },

        notification_contact() {
            const {
                NotificationContactName: name,
                NotificationContactPhoneNumber: phone_number,
                NotificationContactEmail: email,
            } = this.broadband.contact_details

            return `${name}, ${email}, ${phone_number}`
        },

        rows() {
            return [
                { key: 'address', title: 'Address' },
                { key: 'rfs_date', title: 'RFS Date' },
                { key: 'customer', title: 'Customer' },

                { key: 'plan_name', title: 'Plan name' },
                { key: 'plan_description', title: 'Plan description' },
                { key: 'order_type', title: 'Order Type' },
                ...(this.OrderExistingONTID ? [{ key: 'OrderExistingONTID', title: 'ONT ID' }] : []),
                ...(this.OrderExistingProductID ? [{ key: 'OrderExistingProductID', title: 'Existing Product ID' }] : []),
                { key: 'plan_price', title: 'Plan price' },

                ...(this.service_type ? [{ key: 'service_type', title: 'Service Type' }] : []),
                ...(this.lan_ip_range ? [{ key: 'lan_ip_range', title: 'LAN IP Range' }] : []),
                ...(this.wan_ip ? [{ key: 'wan_ip', title: 'WAN IP' }] : []),
                ...(this.vrf ? [
                    { key: 'vrf', title: 'VRF' },
                    { key: 'additional_details', title: 'Additional Details' },
                ] : []),
                ...(this.dhcp_scope ? [{ key: 'dhcp_scope', title: 'DHCP Scope' }] : []),
                ...(this.site_contact ? [{ key: 'site_contact', title: 'Site Contact' }] : []),
                ...(this.notification_contact ? [{ key: 'notification_contact', title: 'Notify Contact' }] : []),
            ]
        },

        data() {
            return {
                address: this.broadband.location.address,
                rfs_date: this.plan_rfs_date,
                customer: this.customer,

                plan_name: this.plan_name,
                plan_description: this.plan_description,
                plan_price: this.plan_price,
                order_type: this.order_type,
                OrderExistingONTID: this.broadband.plan.OrderExistingONTID,
                OrderExistingProductID: this.broadband.plan.OrderExistingProductID,

                service_type: this.service_type,
                lan_ip_range: this.lan_ip_range,
                wan_ip: this.wan_ip,
                vrf: this.vrf,
                additional_details: this.additional_details,
                dhcp_scope: this.dhcp_scope,
                site_contact: this.site_contact,
                notification_contact: this.notification_contact,
            }
        }
    },
}
</script>

<style lang="scss">
.stage-summary {
    .summary-error {
        color: red;
        font-weight: 400;
        b {
            font-weight: 600;
        }
        padding-bottom: 20px;
    }
    .app-table-details {
        padding-top: 0;
        max-width: 600px;
        margin-bottom: 24px;
    }
}
</style>