<template>
    <div class="stage-location">
        <section class="location">
            <app-error v-model="errors.show" :message="errors.message"></app-error>

            <form @submit.prevent class="form-location">
                <app-input-location-autocomplete v-if="search_by_locationsvc"
                    v-model="location.address"

                    :debounce="250"
                    :with-manually="false"

                    label="Fibre Service Address"
                    :disabled="processing"
                    :with-location-providers="locationProviders"
                    :show-location-provider="true"

                    :error="!!errors.fields.location"

                    @change="resetAvailable"
                    @change-place="onChangePlace"

                    @enter-manually="dialogs.location.show = true"

                    ref="location"
                ></app-input-location-autocomplete>

                <app-input-google-autocomplete-manually v-else
                    v-model="location.address"

                    :with-manually="false"

                    label="Fibre Service Address"
                    :disabled="processing"

                    :error="!!errors.fields.location"

                    @change="resetAvailable"
                    @change-place="onChangePlace"
                    @change-address="checkAvailability"

                    @enter-manually="dialogs.location.show = true"

                    ref="location"
                ></app-input-google-autocomplete-manually>
            </form>
        </section>

        <div v-if="serviceAbilityMessage" class="services">
            <div class="prefix">Service Availability:</div>
            <div class="list">{{ serviceAbilityMessage }}<span v-if="installRequired">&nbsp;(Install Required)</span></div>
        </div>

        <div class="details-list" v-if="show_fibre_info">

            <div class="detail" v-if="have_ont_ids">
                <div class="key">ONTIDs:</div>
                <div class="value">
                    <span class="id" v-for="(id, index) in ont_ids" :key="index">
                        {{ id }}<template v-if="index != ont_ids.length - 1">,</template>
                    </span>
                </div>
            </div>

            <div class="detail" v-if="have_ont_serials">
                <div class="key">ONT Serial Numbers:</div>
                <div class="value">
                    <span class="id" v-for="(id, index) in ont_serials" :key="index">
                        {{ id }}<template v-if="index != ont_serials.length - 1">,</template>
                    </span>
                </div>
            </div>

            <div class="detail" v-if="have_intact_service_ids">
                <div class="key">IntactServiceIDs:</div>
                <div class="value">
                    <span class="id" v-for="(id, index) in intact_service_ids" :key="index">
                        {{ id }}<template v-if="index != intact_service_ids.length - 1">,</template>
                    </span>
                </div>
            </div>

            <div class="detail">
                <div class="key">FibreAvailable:</div>
                <div class="value">{{ FibreAvailable }}</div>
            </div>

            <div class="detail">
                <div class="key">DwellingType:</div>
                <div class="value">{{ DwellingType }}</div>
            </div>

            <!-- <div class="detail">
                <div class="key">CO:</div>
                <div class="value">{{ CO }}</div>
            </div> -->

            <div class="detail" v-if="show_fibre_in_a_day">
                <div class="key">Fibre-In-A-Day:</div>
                <div class="value">{{ fibre_in_a_day }}</div>
            </div>

            <div class="detail" v-if="show_is_row">
                <div class="key">Right of Way:</div>
                <div class="value">{{ is_row }}</div>
            </div>

            <div class="detail" v-if="show_consented">
                <div class="key">Consented:</div>
                <div class="value">{{ consented }}</div>
            </div>
        </div>

        <section class="location-variants" v-if="location.available !== false && location.variants.list">
            <h5>Please select your location:</h5>

            <app-radio-buttons
                v-model="location.variants.checked"
                
                :items="location.variants.list"
                :return-item="true"

                key-value="Formatted"
                key-label="Formatted"

                @select="onLocationSelected"
            ></app-radio-buttons>
        </section>

        <button v-if="location.available && !serviceable" class="btn btn-primary btn-request" @click="confirm">Request Manual Qualification</button>
        <button v-else class="btn btn-primary btn-small btn-confirm" @click="confirm" :disabled="disabled && serviceable == null">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'

import appInputGoogleAutocompleteManually from '@/components/app-input-google-autocomplete-manually'
import appInputLocationAutocomplete from '@/components/app-input-location-autocomplete'

import appRadioButtons from '@/components/app-radio-buttons'

import BroadbandValidator from '@/validators/broadband-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        broadband: { type: Object, required: true },
    },

    components: {
        appError,

        appInputGoogleAutocompleteManually,
        appInputLocationAutocomplete,

        appRadioButtons,
    },

    data() {
        return {
            disabled: true,
            serviceAbilityMessage: '',
            serviceable: null,
            installRequired: null,
            forceRefresh: false,

            locationProviders: ['UFB'],
            // locationProviders: ['CHORUS', 'SLAM'],

            location: {
                address: '',
                location: {},
                prediction: {},

                variants: {
                    list: null,
                    checked: null,
                },

                available: null,

                FibreInfo: null,
            },

            errors: {
                show: false,
                message: '',
                fields: {},
            },

            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        setAddress(address) {
            this.location.address = address

            this.$refs.location.onChangeWord(address)
        },
        
        init() {
            this.location = {
                ...this.location,
                ...this.broadband.location
            }
        },

        confirm() {
            this.$emit('ready', { location: this.location })
            this.goNext()
        },

        goNext() {
            this.processing = true

            this.$store.dispatch('saveBroadbandLocation', { address: this.location.address }).then(() => {
                this.$emit('go-next')
                this.processing = false
            }).catch(error => {
                this.errors.show = true
                this.errors.message = errMessage(error)
                this.processing = false
            })
        },

        resetAvailable() {
            this.location.FibreInfo = null

            this.location.available = null
            this.location.variants = {
                list: null,
                checked: null,
            }
            this.serviceAbilityMessage = ''
            this.serviceable = null
            this.installRequired = null

            this.$emit('reset')
        },

        onChangePlace(address, prediction) {
            this.location.address = address
            this.location.prediction = prediction

            if (this.search_by_locationsvc) {
                this.onLocationSelected(prediction)
            }
        },

        onAddressEnter(address) {
            this.serviceAbilityMessage = '';
            this.location.address = address
            this.checkAvailability()
        },

        checkAvailability() {
            this.serviceAbilityMessage = '';

            if (this.validation('location')) {
                this.processing = true

                this.resetAvailable()

                if (this.search_by_locationsvc) {
                    const payload = {
                        addresstext: this.location.address,
                    }

                    this.$store.dispatch('searchBroadbandLocation', payload).then(locations => {

                        // if (locations.length > 1) {
                            this.location.variants.list = locations
                        // } else {
                        //     this.location.available = true

                        //     this.$nextTick(() => {
                        //         this.disabled = false
                        //     })
                        // }

                        this.processing = false
                    }).catch(error => {
                        this.location.available = false

                        this.processing = false
                    })
                } else {
                    const address = this.location.address

                    this.$store.dispatch('checkBroadbandAvailable', { address }).then(locations => {
                        if (locations.length > 1) {
                            this.location.variants.list = locations
                            this.disabled = true
                        } else {
                            this.location.available = true

                            this.$nextTick(() => {
                                this.disabled = false
                            })
                        }

                        this.processing = false
                    }).catch(error => {
                        this.location.available = false

                        this.processing = false
                    })
                }
            }
        },

        onLocationSelected(location) {
            this.serviceAbilityMessage = ''

            if (this.search_by_locationsvc) {
                this.processing = true

                let payload = {
                    SPID: this.current_spid,
                    LocationProvider: location.LocationProvider,
                    LocationProviderIDX: location.LocationProviderIDX,
                }
                console.log('onLocationSelected:', location)
                if (this.forceRefresh) {
                    payload.ForceRefresh = true
                    console.log('lets force refresh')
                }

                this.serviceAbilityMessage = 'Please wait, checking...'

                this.$store.dispatch('api_location/GetServiceAvailability', payload)
                    .then(apidata => {
                        this.location.address = location.Formatted
                        this.location.location = location
                        this.location.available = true

                        // Check if FWA is available
                        let isFibreServiceable = false

                        for (let i = 0; i < apidata.Services.length; i++) {
                            if (apidata.Services[i].AccessType && (apidata.Services[i].AccessType == "ufb")) {
                                if (apidata.Services[i].ServiceabilityType && (apidata.Services[i].ServiceabilityType == "serviceable" || apidata.Services[i].ServiceabilityType == "install-required")) {
                                    isFibreServiceable = true

                                    if (apidata.Services[i].ServiceabilityType == "install-required") {
                                        this.installRequired = true
                                    }

                                    this.location.FibreInfo = apidata.Services[i].FibreInfo
                                    if (this.location.FibreInfo) {
                                        const numONTIDs = Array.isArray(this.location.FibreInfo.ONTIDs) ? this.location.FibreInfo.ONTIDs.length : 0
                                        const numONTSerials = Array.isArray(this.location.FibreInfo.ONTSerials) ? this.location.FibreInfo.ONTSerials.length : 0
                                        if (!this.forceRefresh && numONTIDs != numONTSerials) {
                                            this.forceRefresh = true
                                            this.serviceAbilityMessage = 'Refreshing, please wait...'
                                            this.serviceable = true
                                            this.disabled = true
                                            return this.onLocationSelected(location)
                                        }
                                    }
                                    this.forceRefresh = false
                                    break
                                }
                            }
                        }

                        if (isFibreServiceable) {
                            this.serviceAbilityMessage = 'Fibre available'
                            this.serviceable = true
                            this.broadband.location_provider = this.location.LocationProvider
                            this.broadband.location_provider_idx = this.location.LocationProviderIDX
                            this.broadband.location_address = this.location.address
                            this.broadband.PrequalDetails = apidata

                            this.$nextTick(() => {
                                this.disabled = false
                            })
                        } else {
                            this.disabled = true
                            this.serviceAbilityMessage = 'No Fibre service available'
                            this.serviceable = false
                        }

                        this.processing = false
                    })
                    .catch(error => {
                        this.$store.dispatch('addToast', {
                            message: errMessage(error),
                            type: 'error',
                            delay: 5000,
                        })

                        this.serviceAbilityMessage = error && error.response && error.response.status == 404
                            ? 'No Fibre services available'
                            : errMessage(error)
                        this.serviceable = false
                        
                        this.location.available = false
                        this.disabled = true
                        this.processing = false
                    })
            } else {
                this.location.address = location.Formatted
                this.location.available = true

                this.broadband.location_provider = this.location.LocationProvider
                this.broadband.location_provider_idx = this.location.LocationProviderIDX
                this.broadband.location_address = this.location.address

                this.$nextTick(() => {
                    this.disabled = false
                })
            }
        },

        validation(target) {
            let is_valid = true

            this.errors = {
                show: false,
                message: '',
                fields: {},
            }

            let values = null

            switch (target) {
                case 'location': {
                    values = {
                        location: this.location.address,
                    }
                } break

                case 'contacts': {
                    values = {
                        name: this.contacts.user.name,
                        email: this.contacts.user.email,
                        phone: this.contacts.user.phone,
                    }
                }
            }

            for (const key in values) {
                if (BroadbandValidator.isRuleExists(key)) {
                    if (BroadbandValidator.isInvalid(key, values[key])) {
                        this.errors.fields[ key ] = BroadbandValidator.getError()
                    }
                }
            }

            return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }

            const errors = Object.keys(this.errors.fields)

            if (errors.length) {
                this.errors.show = true
                this.errors.message = this.errors.fields[ errors[0] ]
            } else {
                this.errors.show = false
            }

            return this.errors.show
        },
    },

    watch: {
        location: {
            handler() {
                if (this.location.available) {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            },
            deep: true,
        },

        processing() {
            this.$emit('processing', this.processing)
        },
    },

    computed: {
        ...mapGetters({
            'current_spid': 'current_spid',
            'ufb_search_providers': 'ufb_search_providers',
            'search_by_google': 'broadband_location_search_by_google',
            'search_by_locationsvc': 'broadband_location_search_by_locationsvc',
        }),

        show_fibre_info() {
            return Boolean(this.location.FibreInfo)
        },

        ont_ids() {
            return Array.isArray(this.location.FibreInfo.ONTIDs)
            ? this.location.FibreInfo.ONTIDs
            : []
        },
        have_ont_ids() {
            return this.ont_ids.length > 0
        },

        ont_serials() {
            return Array.isArray(this.location.FibreInfo.ONTSerials)
            ? this.location.FibreInfo.ONTSerials
            : []
        },
        have_ont_serials() {
            return this.ont_serials.length > 0
        },

        intact_service_ids() {
            return Array.isArray(this.location.FibreInfo.IntactServiceIDs)
            ? this.location.FibreInfo.IntactServiceIDs
            : []
        },
        have_intact_service_ids() {
            return this.intact_service_ids.length > 0
        },

        FibreAvailable() {
            return this.location.FibreInfo.FibreAvailable
        },

        DwellingType() {
            return this.location.FibreInfo.DwellingType
        },

        CO() {
            return this.location.FibreInfo.CO
        },

        show_fibre_in_a_day() {
            return this.location.FibreInfo.FibreInADay === true
        },
        fibre_in_a_day() {
            return this.location.FibreInfo.FibreInADay
        },

        show_is_row() {
            return this.location.FibreInfo.IsROW === true
        },
        is_row() {
            return this.location.FibreInfo.IsROW
        },

        show_consented() {
            return this.location.FibreInfo.Consented === false
        },
        consented() {
            return this.location.FibreInfo.Consented
        }
    },
}
</script>

<style lang="scss">
.stage-location {
    .location {
        width: 100%;
        max-width: 657px;

        form {
            display: flex;
            justify-content: center;
            align-items: center;

            .app-input-google-autocomplete-manually,
            .app-input-location-autocomplete {
                border-radius: $border-radius-secondary;

                input {
                    border-radius: $border-radius-secondary;
                }
            }
        }
    }

    .services {
        display: flex;
        width: 100%;
        max-width: 660px;
        margin-top: 16px;
        line-height: 24px;
        font-size: 16px;

        .prefix {
            padding-right: 32px;
            font-size: 18px;
            font-weight: bold;
            color: var(--color-text-primary);
        }

        .list {
            color: var(--color-text-primary);
        }
    }

    .details-list {
        margin-top: 16px;

        color: var(--color-text-primary);

        .detail {
            display: flex;

            margin-bottom: 16px;

            .key {
                width: 164px;

                font-size: 18px;
                font-weight: bold;
            }

            .value {
                .id {
                    display: block;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .location-variants {
        margin-top: 35px;

        h5 {
            font-size: 28px;
            line-height: 32px;
        }

        .app-radio-buttons {
            padding: 16px 0;
            background: transparent;

            input[type=radio] {
                &+label {
                    margin: 0;
                    padding: 7px 0;

                    &>.label {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .btn-confirm,
    .btn-request {
        margin-top: 24px;
    }

    .btn-request {
        width: auto;
        max-width: none;
        height: auto;

        padding: 12px;

        line-height: 24px;
    }
}

@media (max-width: $mobile-size) {
    .stage-location {
        .services {
            flex-direction: column;
            font-size: 14px;

            .prefix {
                font-size: 16px;
                padding: 0;
            }

            .list {
                margin-top: 4px;
            }
        }

    .details-list {
        font-size: 14px;

        .detail {
            flex-direction: column;

            margin-bottom: 8px;

            .key {
                width: auto;

                font-size: 16px;
            }
        }
    }

        .location-variants {
            margin-top: 24px;

            h5 {
                font-size: 24px;
                line-height: 32px;
            }

            .app-radio-buttons {
                input[type=radio] {
                    &+label {
                        &>.label {
                            font-size: 16px;
                        }
                    }
                }
            }

            &+.actions {
                margin-top: 24px;
            }
        }
    }
}
</style>