import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    ProductCatalog: {
        name: 'Product catalog',
        required: true,
    },

    OrderType: {
        name: 'Order type',
        required: true,
    },

    RFSDate: {
        name: 'RFS Date',
        required: true,
    },
})