<template>
    <base-layout>
        <div class="container page-fibre-bulk-add-service">
            <div class="row">
                <div class="col-12">
                    <app-loader v-if="loading" />

                    <h1 class="heading">Add Fibre Broadband</h1>

                    <div class="progress-bar">
                        <div class="details">
                            <h3 class="heading">Address:</h3>

                            <div class="steps">
                                <span class="current">{{ current_location_index }}</span><span class="total">/{{ locations_total }}</span>
                            </div>
                        </div>

                        <button class="btn btn-primary btn-small btn-next" v-if="show_next_button" @click="goToNextAddress">Next</button>
                    </div>

                    <div class="broadband-stages">
                        <h3>Fibre Stages</h3>

                        <div class="stages">
                            <div class="stage" v-for="(stage, key) in stages.list" :key="key"
                                :class="{
                                    [`step-${ key }`]: true,
                                    opened: stage.name == stages.opened,
                                    completed: stage.completed,
                                    current: stage.name == stages.current,
                                }"
                            >
                                <div class="stage-head">
                                    <div class="btn btn-stage btn-stage-point">
                                        <div class="stage-circle">
                                        </div>
                                        <span></span>
                                    </div>
                                    <button class="btn btn-stage btn-stage-expand" @click="toggleStage(stage)" :disabled="!stage.ready">{{ stage.title }}</button>
                                </div>

                                <div class="stage-body">
                                    <component v-if="renderComponent"
                                        :is="stage.body"
                                        :broadband="broadband"

                                        @ready="onStageReady"
                                        @reset="onStageReset(stage)"
                                        @go-next="goNext"

                                        @confirm="onConfirm"

                                        ref="stage"
                                    ></component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'

import stageAddressSearch from './stages/address-search'
import stageCustomerDetails from '@/components/app-stage-customer-details'
import stageChoosePlan from './stages/choose-plan'
import stageContactDetails from './stages/contact-details'
import stageSummary from './stages/summary'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        baseLayout,
        appLoader,
    },

    data() {
        return {
            renderComponent: true,

            current_location: null,
            current_location_index: 0,
            locations_total: 0,

            broadband: {
                location: {},

                location_provider: '',
                location_provider_idx: '',

                customer: {},

                plan: {},

                contact_details: {},

                services: null,
                package: null,
            },

            stages: {
                opened: 'address-search',
                current: 'address-search',

                list: {
                    'address-search': {
                        name: 'address-search',
                        ready: true,
                        completed: false,
                        title: 'Address search',
                        body: stageAddressSearch,

                        reset: {
                            broadband: {
                                plan: {
                                    product: {},
                                    product_catalog: '',
                                },
                            }
                        },
                    },

                    'customer-details': {
                        name: 'customer-details',
                        ready: false,
                        completed: false,
                        title: 'Customer details',
                        body: stageCustomerDetails,
                    },

                    'choose-plan': {
                        name: 'choose-plan',
                        ready: false,
                        completed: false,
                        title: 'Choose plan',
                        body: stageChoosePlan,
                    },

                    'contact-details': {
                        name: 'contact-details',
                        ready: false,
                        completed: false,
                        title: 'Contact Details',
                        body: stageContactDetails,
                    },

                    'summary': {
                        name: 'summary',
                        ready: false,
                        completed: false,
                        title: 'Summary',
                        body: stageSummary,
                    },
                },
            },

            error: {
                show: false,
                message: '',
            },

            loading: false,
        }
    },

    created() {
        this.locations_total = Object.keys(this['locations/locations']).length
    },

    mounted() {
        this.init()
    },

    methods: {
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;

                this.$nextTick(() => {
                    this.init()
                })
            })
        },

        goToNextAddress() {
            this.deleteLocation()

            if (this.have_at_least_one_location) {
                this.reset()
            } else {
                this.$router.push({ name: 'fibre-orders' })
            }
        },

        init() {
            if (this.have_at_least_one_location) {
                // console.log('this[locations/locations]', this['locations/locations'])
                const key = Object.keys(this['locations/locations'])[0]
                const parts = key.split('|')
                if (parts.length >= 2) {
                    this.broadband.location_provider = parts[0]
                    this.broadband.location_provider_idx = parts[1]
                }

                this.current_location = this['locations/locations'][key]
                this.current_location_index = this.current_location_index + 1

                this.$refs.stage[0].setAddress(this.current_location.Formatted)
            } else {
                this.$router.push({ name: 'fibre-add-service' })
            }
        },

        deleteLocation() {
            const key = `${ this.current_location.LocationProvider }|${ this.current_location.LocationProviderIDX }`

            this.$store.commit('locations/deleteLocation', key)
        },

        reset() {
            this.current_location = null

            this.broadband = {
                location: {},

                customer: {},

                plan: {},

                contact_details: {},

                services: null,
                package: null,
            }

            this.stages = {
                opened: 'address-search',
                current: 'address-search',

                list: {
                    'address-search': {
                        name: 'address-search',
                        ready: true,
                        completed: false,
                        title: 'Address search',
                        body: stageAddressSearch,
                    },

                    'customer-details': {
                        name: 'customer-details',
                        ready: false,
                        completed: false,
                        title: 'Customer details',
                        body: stageCustomerDetails,
                    },

                    'choose-plan': {
                        name: 'choose-plan',
                        ready: false,
                        completed: false,
                        title: 'Choose plan',
                        body: stageChoosePlan,
                    },

                    'contact-details': {
                        name: 'contact-details',
                        ready: false,
                        completed: false,
                        title: 'Contact Details',
                        body: stageContactDetails,
                    },

                    'summary': {
                        name: 'summary',
                        ready: false,
                        completed: false,
                        title: 'Summary',
                        body: stageSummary,
                    },
                },
            }

            this.error = {
                show: false,
                message: '',
            }

            this.loading = false

            this.forceRerender()
        },

        goNext() {
            this.stage.completed = true

            const next_stage_index = this.stages_indexes[this.stages.opened] + 1

            if (next_stage_index < this.stages_sequence.length) {
                this.stages.opened = this.stages_sequence[ next_stage_index ]
                this.stages.current = this.stages.opened

                this.$nextTick(() => {
                    this.$refs.stage[next_stage_index].init()
                })
            }
        },

        onStageReady(broadband) {
            if (broadband) {
                this.broadband = {
                    ...this.broadband,
                    ...broadband,
                }

                this.toggleStageReady(true)
            } else {
                switch (this.step.name) {
                    case 'address-search': {
                        this.broadband.location = {}
                    } break

                    case 'configure-plan': {
                        this.broadband.package = null
                    } break
                }

                this.toggleStageReady(false)
            }
        },

        onStageReset(stage) {
            if (stage.completed && 'reset' in stage) {
                this.stages.current = stage.name

                let reset_stages = false
                for (const key in this.stages.list) {
                    if (reset_stages) {
                        this.stages.list[key].ready = false
                        this.stages.list[key].completed = false
                    } else if (this.stages.list[key].name == stage.name) {
                        reset_stages = true
                        this.stages.list[key].completed = false
                    }
                }

                if (stage.reset
                    && typeof stage.reset == 'object'
                    && stage.reset.broadband
                ) {
                    const reset_broadband = (broadband, data) => {
                        for (const key in data) {
                            if (key in broadband) {
                                if (data[key] == null) {
                                    broadband[key] = data[key]
                                } else if (Array.isArray(data[key])) {
                                    broadband[key] = [...data[key]]
                                } else if (typeof data[key] == 'object') {
                                    if (Object.keys(data[key]).length) {
                                        reset_broadband(broadband[key], data[key])
                                    } else {
                                        broadband[key] = {...data[key]}
                                    }
                                } else {
                                    broadband[key] = data[key]
                                }
                            }
                        }
                    }
                    reset_broadband(this.broadband, stage.reset.broadband)
                }
            }
        },

        toggleStageReady(ready) {
            if (ready) {
                const next_stage = this.stages_sequence[ this.stages_indexes[this.stages.opened] + 1 ]

                if (next_stage) {
                    this.stages.list[ next_stage ].ready = true
                }
            } else {
                /*
                    for (let i = this.stages_indexes[this.stages.opened] + 1, len = this.stages_sequence.length; i < len; i++) {
                        const next_stage = this.stages_sequence[i]

                        this.stages.list[ next_stage ].ready = false

                        this.stages.current = this.stages.opened
                    }
                */
            }
        },

        onChangeProcessing(processing) {
            this.processing = processing
        },

        toggleStage(stage, is_not_closed) {
            if (is_not_closed) {
                this.stages.opened = stage.name
            } else {
                this.stages.opened = this.stages.opened == stage.name ? null : stage.name
            }
        },

        onConfirm() {
            this.loading = true

            const ufborder = {
                SPID: this.current_spid,

                CustomerUUID: this.broadband.customer.UUID,

                Address: this.broadband.location.address,

                RFSDate:                 this.broadband.plan.rfs_date.getTime() * 1000000,
                ServiceType:             this.broadband.plan.is_service_managed ? 'Managed' : 'Unmanaged',
                OrderType:               this.broadband.plan.order_type,
                LANIPRange:              this.broadband.plan.LAN_IP_Range,
                WANIP:                   this.broadband.plan.WAN_IP,
                VRFDetails:              this.broadband.plan.VRF_Details,
                AdditionalDetails:       this.broadband.plan.Additional_Details,
                DHCPScope:               this.broadband.plan.DHCP_Scope,
                IPHelperDetails:         this.broadband.plan.IP_Helper_Details,
                ProductAvailabilityUUID: this.broadband.plan.product_catalog,
                PrequalUUID:             this.broadband.PrequalDetails.UUID,

                SiteContactName:         this.broadband.contact_details.SiteContactName,
                SiteContactPhone:        this.broadband.contact_details.SiteContactPhoneNumber,
                NotificationName:        this.broadband.contact_details.NotificationContactName,
                NotificationPhoneNumber: this.broadband.contact_details.NotificationContactPhoneNumber,
                NotificationEmail:       this.broadband.contact_details.NotificationContactEmail,

                AddressID: '1234',
            }

            if (this.broadband.plan.OrderExistingONTID && this.broadband.plan.order_type != 'ConnectAdditionalONT') {
                ufborder.OrderExistingONTID = this.broadband.plan.OrderExistingONTID
            }

            if (this.broadband.plan.OrderExistingProductID && this.broadband.plan.order_type != 'ConnectAdditionalONT') {
                ufborder.OrderExistingProductID = this.broadband.plan.OrderExistingProductID
            }

            this.$store.dispatch('api_ufbprod/AddUFBOrder', ufborder)
                .then(response => {
                    this.error.show = false
                    this.error.message = ''

                    this.loading = false

                    this.$store.dispatch('addToast', {
                        message: `Fibre with order ID ${ response.UUID } has been added`,
                        type: 'success',
                        delay: 5000,
                    })

                    this.goToNextAddress()
                })
                .catch(error => {
                    this.error.show = true
                    this.error.message = errMessage(error)

                    this.broadband.order_err_message = errMessage(error)

                    this.loading = false

                    console.error(error)
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'locations/locations',
        ]),

        have_at_least_one_location() {
            return Object.keys(this['locations/locations']).length > 0
        },

        show_next_button() {
            return this.current_location_index < this.locations_total
        },

        stage() {
            return this.stages.list[ this.stages.opened ]
        },

        stages_indexes() {
            let index = 0,
                indexes = {}

            for (const key in this.stages.list) {
                indexes[ key ] = index++
            }

            return indexes
        },

        stages_sequence() {
            return Object.keys(this.stages.list)
        },
    },
}
</script>

<style lang="scss">
.page-fibre-bulk-add-service {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .progress-bar {
        display: flex;
        justify-content: space-between;

        margin-bottom: 16px;

        .details {
            display: flex;
            align-items: center;

            h3 {
                &.heading {
                    margin-right: 16px;

                    color: var(--color-table-primary);
                }
            }

            .steps {
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;

                color: var(--color-table-secondary);

                .current {
                    color: var(--color-table-primary);
                }
            }
        }
    }

    .broadband-stages {
        padding: 24px 24px 32px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-tertiary);

        /* @todo remove this "overflow: hidden;" after making for :before dynamic height */
        overflow: hidden;

        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
        }

        .stages {
            margin: 32px 0 0 8px;
            border-radius: 0 0 $border-radius-secondary $border-radius-secondary;

            /* @todo make for :before dynamic height */
            // overflow: hidden;

            .btn-stage {
                padding: 0;

                &:not(button) {
                    cursor: default;
                }

                &.btn-stage-point {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    background: transparent;
                    flex-shrink: 0;
                    margin-right: 16px;

                    &:before {
                        content: "";
                        display: inline-block;
                        width: 2px;
                        height: 1000px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background: var(--color-stages-icons-bg);
                        transform: translate(-50%, 0);
                        pointer-events: none;
                    }

                    .stage-circle {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(0.5);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 100%;
                        height: 100%;

                        background-color: var(--color-stages-icons-bg);

                        border: 2px solid var(--color-stages-icons-bg);

                        border-radius: 50%;

                        pointer-events: none;

                        transition: $transition-duration-primary;

                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        width: 24px;
                        height: 24px;

                        color: var(--color-stage-icon);

                        opacity: 0;
                        visibility: hidden;

                        transition: $transition-duration-primary;
                    }

                    // &:after {
                    //     // content: "";
                    //     // display: inline-block;
                    //     // width: 50%;
                    //     // height: 50%;
                    //     // border: 2px solid var(--color-stages-icons-bg);
                    //     // border-radius: 50%;
                    //     // background: var(--color-stages-icons-bg) none no-repeat center;
                    //     // box-sizing: border-box;
                    //     // position: absolute;
                    //     // top: 50%;
                    //     // left: 50%;
                    //     // transform: translate(-50%, -50%);
                    //     // pointer-events: none;
                    //     // transition: $transition-duration-primary;
                    // }

                    &.horizontal {
                        &:before {
                            width: 1000px;
                            height: 2px;
                            transform: translate(0, -50%);
                        }
                    }

                    &[disabled] {
                        opacity: 1;
                    }
                }

                &.btn-stage-expand {
                    width: auto;
                    height: 100%;
                    background: transparent;
                    font-size: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: var(--color-stages-title);
                    text-transform: $stages-btn-text-transform;
                    transition: $transition-duration-primary;

                    &[disabled] {
                        color: var(--color-stages-title);
                        cursor: default;
                    }
                }
            }

            .stage {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;

                    .btn-stage-point {
                        &:before {
                            background: var(--color-component-bg-primary);
                        }
                    }
                }

                .stage-head {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    i {
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        background: none no-repeat center;
                        background-size: contain;
                        flex-shrink: 0;
                    }
                }

                .stage-body {
                    display: none;
                    padding: 24px 0 0 #{ 32px + 16px };
                }

                &.opened {
                    .stage-head {
                        .btn-stage-expand {
                            color: var(--color-stages-title-active);

                            &:after {
                                transform: rotateX(180deg);
                            }
                        }
                    }

                    .stage-body {
                        display: block;
                    }
                }

                &.completed {
                    .btn-stage {
                        &.btn-stage-point {
                            &:before {
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }

                            // &:after {
                            //     width: 100%;
                            //     height: 100%;
                            //     border: 2px solid var(--color-stages-icons-bg-active);
                            //     background-color: var(--color-stages-icons-bg-active);
                            // }
                        }

                        &.btn-stage-expand {
                            &:hover {
                                color: var(--color-stages-title-active);
                            }
                        }
                    }
                }

                &.current {
                    .btn-stage {
                        &.btn-stage-point {
                            .stage-circle {
                                transform: translate(-50%, -50%) scale(1);
                                border: 2px solid var(--color-stages-icons-bg-active);
                                background-color: var(--color-stages-icons-bg-active);
                            }

                            span {
                                opacity: 1;
                                visibility: visible;

                                &:before {
                                    color: var(--color-stage-icon-active);
                                }
                            }

                            // &:after {
                            //     width: 100%;
                            //     height: 100%;
                            //     border: 2px solid var(--color-stages-icons-bg-active);
                            //     background-color: var(--color-stages-icons-bg-active);
                            // }
                        }
                        
                        &.btn-stage-expand {
                            color: var(--color-stages-title-active);
                        }
                    }
                }
            }



            .stage {
                &.step-address-search {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-map-mark);
                                }
                            }
                        }
                    }
                }

                &.step-customer-details {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-add-customer);
                                }
                            }
                        }
                    }
                }

                &.step-choose-plan {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-create-service);
                                }
                            }
                        }
                    }
                }

                &.step-contact-details {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-plan);
                                }
                            }
                        }
                    }
                }

                &.step-summary {
                    &.completed,
                    &.current {
                        .btn-stage {
                            &.btn-stage-point {
                                span {
                                    @include icon-before($icon-summary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-fibre-bulk-add-service {
        .broadband-stages {
            padding: 24px 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fibre-bulk-add-service {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .progress-bar {
            flex-direction: column;

            .btn-next {
                margin-top: 16px;
            }
        }

        .broadband-stages {
            padding: 16px 16px 16px 8px;

            h3 {
                font-size: 24px;
                line-height: 32px;
            }

            .stages {
                margin: 20px 0 0 0;

                .btn-stage {
                    &.btn-stage-point {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;

                        span {
                            transform: translate(-50%, -50%) scale(0.8);
                        }

                        // &:after {
                        //     background-size: 70%;
                        // }
                    }

                    &.btn-stage-expand {
                        font-size: 16px;
                    }
                }

                .stage {
                    .stage-body {
                        padding-left: 26px;

                        .app-input {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>