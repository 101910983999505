<template>
    <div class="stage-contact-details">
        <div class="form-section">
            <div class="title">Site Contact</div>

            <div class="form-group site-contact">
                <div class="form-controls">
                    <app-input
                        v-model="contact_details.SiteContactName"

                        label="Name"
                        :required="true"

                        :error="errors.fields.SiteContactName"

                        @change="errors.fields.SiteContactName = null"
                    />
                </div>

                <div class="form-controls">
                    <app-input
                        v-model="contact_details.SiteContactPhoneNumber"

                        label="Phone Number"
                        :required="true"

                        :error="errors.fields.SiteContactPhoneNumber"

                        @change="errors.fields.SiteContactPhoneNumber = null"
                    />
                </div>
            </div>

            <div class="title">Notification Contact</div>

            <div class="form-group notification-contact">
                <div class="form-controls">
                    <app-input
                        v-model="contact_details.NotificationContactName"

                        label="Name"

                        :error="errors.fields.NotificationContactName"

                        @change="errors.fields.NotificationContactName = null"
                    />
                </div>

                <div class="form-controls">
                    <app-input
                        v-model="contact_details.NotificationContactPhoneNumber"

                        label="Phone Number"

                        :error="errors.fields.NotificationContactPhoneNumber"

                        @change="errors.fields.NotificationContactPhoneNumber = null"
                    />
                </div>

                <div class="form-controls">
                    <app-input
                        v-model="contact_details.NotificationContactEmail"

                        label="Email Address"

                        :error="errors.fields.NotificationContactEmail"

                        @change="errors.fields.NotificationContactEmail = null"
                    />
                </div>
            </div>
        </div>

        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appInput from '@/components/app-input'

import BroadbandValidator from '@/validators/broadband-validator'

export default {
    components: {
        appInput,
    },

    props: {
        broadband: { type: Object, required: true },
    },

    data() {
        return {
            contact_details: {
                SiteContactName: '',
                SiteContactPhoneNumber: '',

                NotificationContactName: '',
                NotificationContactPhoneNumber: '',
                NotificationContactEmail: '',
            },

            errors: {
                fields: {},
            },
        }
    },

    methods: {
        init() {
            this.contact_details.NotificationContactName = `${this.id_first_name} ${this.id_last_name}`
            this.contact_details.NotificationContactPhoneNumber = this.id_mobile
            this.contact_details.NotificationContactEmail = this.id_email
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                SiteContactName:        { rule: 'fullname', message: 'Please, enter name'               },
                SiteContactPhoneNumber: { rule: 'phone',    message: 'Please, enter valid phone number' },

                NotificationContactName:        { rule: 'fullname', message: 'Please, enter name'               },
                NotificationContactPhoneNumber: { rule: 'phone',    message: 'Please, enter valid phone number' },
                NotificationContactEmail:       { rule: 'email',    message: 'Please, enter valid email'        },
            }

            BroadbandValidator.mixin({
                phone: {
                    required: true,
                },
            })

            for (const key in fields) {
                if (BroadbandValidator.isRuleExists(fields[key].rule)) {
                    if (BroadbandValidator.isInvalid(fields[key].rule, this.contact_details[key], fields[key].message)) {
                        this.errors.fields[key] = BroadbandValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.$emit('ready', { contact_details: this.contact_details })

                this.$emit('go-next')
            }
        },
    },

    computed: {
        ...mapGetters([
            'id_first_name',
            'id_last_name',
            'id_email',
            'id_mobile',
        ]),
    },
}
</script>

<style lang="scss">
.stage-contact-details {
    max-width: 1170px;

    .form-section {
        .title {
            margin-bottom: 24px;

            font-size: 18px;
            line-height: 24px;
        }

        .form-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            margin-bottom: 30px;

            .form-controls {
                width: 100%;
            }

            &.site-contact {
                max-width: 770px;

                .form-controls {
                    max-width: calc(50% - 15px);
                }
            }

            &.notification-contact {
                .form-controls {
                    max-width: calc(100% / 3 - 20px);
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .stage-contact-details {
        .form-section {
            .form-group {
                flex-direction: column;

                &.site-contact,
                &.notification-contact {
                    .form-controls {
                        max-width: 370px;

                        margin-bottom: 30px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .stage-contact-details {
        .form-section {
            .form-group {
                margin-bottom: 24px;

                &.site-contact,
                &.notification-contact {
                    .form-controls {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
}
</style>